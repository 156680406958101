<template>
	<p align="center" style="margin-bottom: 0cm; border: none; padding: 0cm">
	<font color="#000000"><font size="3" style="font-size: 12pt"><b>Privacy
	Policy</b></font></font></p>
	<p align="center" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%"><a name="_GoBack"></a>
	<font color="#000000">(updated version published in </font><font color="#000000"><span lang="uk-UA">___________</span></font><font color="#000000">)</font></p>
	<p style="margin-bottom: 0cm; line-height: 100%"><br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">Hi All,</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">We, Teach&amp;Draw
	Limited, a company incorporated under the laws of the Republic of
	Cyprus, registered address: Makariou III, 22 Makaria Center, 4</font></font><font color="#000000"><sup><font face="Cambria, serif">th</font></sup></font><font color="#000000"><font face="Cambria, serif">
	floor, office 403, 6016 Larnaca, Cyprus, registration number:
	HE385979 (hereinafter referred to as “We” and “Company”),
	have prepared this Privacy Policy (the “Policy”) to show how
	responsibly and seriously we treat the privacy of information,
	including personal data, of our applications users and visitors of
	http://teachdraw.com (the “website”).</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">This Policy will
	help you understand:</font></font></p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">what is personal
		data;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">what personal data
		we collect;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">how and why we
		collect it;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">whom we can share
		personal data with;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">how to contact us
		if you have any questions regarding personal data processing.</font></font></p>
	</li></ul>
	<p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif"><b>Personal data
	protection statement</b></font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">We strive to
	protect the privacy of personal data that we obtain and process.
	During data processing, the Company undertakes to take all necessary
	technical and regulatory action in strict compliance with applicable
	law and solely upon legal grounds permitting any such processing.</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">All our
	applications and services that are available for use on mobile
	devices, personal computers, or any other platforms including the
	website, to the extent related to personal data processing and
	protection are governed by this Privacy Policy. Therefore, by using
	any of our applications and/or services you agree with this Policy.</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">If you do not want
	us to collect and process information about you in accordance with
	this Policy, you may not, unfortunately, use our applications and/or
	services.</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">When we control
	data collection methods and identify the goals for which such data is
	used, the Company becomes the “data controller” for the purposes
	of the EU General Data Protection Regulation (the “GDPR”), the UK
	Data Protection Act 2018, and any other European data protection
	legislation. Under the Law of Ukraine on Personal Data Protection of
	June 1, 2010, the Company is the “personal data owner”. We may
	also be the “data operator” under the 1998 Children's Online
	Privacy Protection Rule (the “COPPA”).</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">We process personal
	data only upon at least one of the following legitimate grounds for
	such processing, including but not limited to:</font></font></p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">you consented to
		your data processing;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">processing is
		required to execute or fulfil an agreement with you (including any
		offer and acceptance), including when you are using our applications
		/ services;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">such processing is
		required under the legislation of the countries to which we provide
		our services or make our applications / services available;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">processing is
		required for the purposes of any legitimate interests of the Company
		as the controller or third party (unless where interests pertaining
		to fundamental rights and freedoms of the data subject that require
		data protection prevail over the above interests).</font></font></p>
	</li></ul>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">At the same time,
	please note that we do not request special categories of personal
	data of our users / visitors.</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">Please note that
	our Privacy Policy may be updated from time to time, including if
	required by applicable law. We suggest that you might want to review
	this Policy from time to time to see if there are any modifications.
	Your continued use of the applications or services after this Policy
	has been updated means your consent to such update. If you do not
	agree to the updates, you may refuse to use our applications /
	services (in particular, by removing the applications from your
	devices).</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">Anyway, you can
	always delete or re-install an application on your mobile device to
	delete or change the personal data accordingly.</font></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>How we obtain personal data</b></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">The Company may obtain your data from various
	sources and with various aims, including, without limitation: </font>
	</p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you play our games or use other services
		in applications or on the website;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you make a purchase through our
		applications (such as a PRO plan of an application or certain game
		characters);</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you create an account on our website;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you watch ads in our applications;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you subscribe to our promotional mailing;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you contact our user support centre;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you talk to other users of our
		applications in a special chat;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">when you apply for a job (send your CV using
		any communication channel).</font></p>
	</li></ul>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Our applications can gather information based
	on the analysis of actions taken during their use. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>What personal data we collect and process</b></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">(A) The following information about you can be
	collected and processed by our applications and/or services used by
	you, including the website:</font></p>
	<ul>
		<li><p style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">geo location (country, state or region, GPS
		location);</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">device information (such as device name /
		model, operating system, browser information, including browser type
		and language settings);</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">device IDs and advertisement ID (if authorised
		by the user);</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">game progress and activities (such as
		remembering the language selected by the user and level of
		difficulty at a certain stage of use);</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">Information that we collect using cookies and
		similar technologies (please read our separate </font><font color="#1f497d">Cookie
		Policy</font><font color="#000000">).</font></p>
	</li></ul>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">(B) The following information can be provided
	by you to use or our partners (third parties) during the use of our
	applications, services, and website:</font></p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">age;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">name;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">contact details (such as email or phone
		number);</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">images / photos;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Times New Roman, serif">social
		media profiles;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Times New Roman, serif">bank /
		credit card details;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Times New Roman, serif">work
		experience and skills (CV).</font></font></p>
	</li></ul>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">(C) In addition to the above, we can also
	process other personal data, if you voluntarily submit it to the
	Company. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">However, if data processing is not required for
	using our applications and/or services by you, or is not explicitly
	required by law, we will delete it.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Children’s data</b></font></p>
	<p style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We treat a user / customer as a child if they
	are under 13 years of age, unless different age limitations apply in
	their country (in certain countries these are individuals under 16).</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We do not intentionally collect and process
	children’s personal data. However, the Company understands that the
	target audience of our applications may include children, and that
	among its users there are probably parents with children who might be
	ordering services for children (such as buying a PRO plan or
	characters in the application). </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Parents or any other legitimate representatives
	who order the relevant services for children have to accept the terms
	of use of the relevant application and/or service of the Company and
	this Policy, and will be responsible for the accuracy of the data
	submitted and provision of the relevant service to the child. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Acceptance of the terms of use and this Policy
	and continued use of any of our applications or services means that
	parents or any other legitimate representatives who order the
	relevant services for children understand that the personal data
	referred to in this Policy may be collected and processed, and have
	consented to this. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">When ordering a service or downloading an
	application for further use, the person who takes the relevant action
	is representing the legitimate interests of the child and is
	personally responsible for the child’s actions with this service
	and/or application in future. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Children must not disclose their real name,
	location, phone number or email address, or any other personal data
	without prior consent of their parents or any other legitimate
	representatives.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We do not encourage children to participate in
	a game by using our applications through offering a reward, and we do
	not encourage any other child’s activities that disclose more
	personal data of the child that actually required to use the
	application.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Our applications and other services do not
	involve any direct disclosure of the child’s personal data to any
	third parties.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Please refer to the relevant sections of this
	Policy below to learn what third parties may get information about
	users and for what purposes.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Purposes of personal data processing</b></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">The Company provides services and makes its
	applications available almost worldwide. We use the collected
	information about users / customers mostly to ensure the appropriate
	quality of our services, in particular, to enhance your gaming
	experience and improve functions and capabilities of our applications
	in future. We may analyse information related to the use of
	applications in order to adapt the user tasks so that they would
	better suit the user’s needs. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We also use personal data for the following
	purposes:</font></p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">offering you a full scope of our services;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">control of age restrictions that meet the
		legislation of your country of residence;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">buying a PRO plan or specific game characters
		in applications;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">personalisation of user experience to provide
		special offers customized just for you;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">content personalisation;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">bug finding and fixing;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">advisory assistance to users;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">use for administrative, analytical and
		statistic purposes;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">ensuring and promoting communication between
		users;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">providing promotional / marketing information,
		including joint special offers of the Company and our partners;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">advertising / direct marketing and efficiency
		assessment;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">subscription management and unsubscribing;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">protection against any malicious actions of
		users and in connection with other security considerations of the
		Company;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">safeguarding of the Company’s legitimate
		interests;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">compliance with other legislative
		requirements, including to provide timely feedback to requests of
		data subjects and supervisory / law enforcement authorities.</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">selection of candidates for a job and
		employment with the Company.</font></p>
	</li></ul>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Please understand that we have to display ads
	in our applications, including third party ads, to raise funding for
	development, distribution and technical support of our applications,
	most of which are free. For example, you might see third party
	advertisements while using our application. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Emailing any advertising / marketing
	notifications to you requires your specific consent that may be
	revoked at any time (including by contacting the data protection
	officer).</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Please only disclose the personal data that is
	required to provide the selected service, mailing or response to your
	specific request / claim. If you decide to disclose your additional
	personal data with use, we will ensure that it is processed with an
	appropriate level of security.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>In-app purchasing</b></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Some of our applications have in-app purchasing
	function (PRO plan or specific gaming characters). </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Please note that all in-app purchases are
	processed by an external payment processor that may collect
	information directly from you. In particular, the payment processor
	may request the payer’s address and bank or credit card details in
	accordance with its own privacy policy (please review it before
	submitting such data to any external processor). </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">External payment processors do not share the
	information provided by you, and we do not have credit card details.
	However, we receive a summary about all purchases made, including the
	transaction amount, which is necessary to give access to your
	purchase in the application.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Storage of personal data</b></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We use cloud services of Google LLC (Google
	Ireland Limited, Gordon House, Barrow Street, Dublin 4) to ensure the
	security of personal data. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Personal data is stored at data centres in
	Europe (Europe-West1, Rue de Ghlin 100, 7331 Saint-Ghislain, Belgium;
	Europe-West4, Oostpolder 4, 9979 XT Eemshaven, Netherlands) and
	United States (US-Central-1, 1430 Veterans Memorial Hwy, Council
	Bluffs, IA 51501; US-Central-2, 4111 N E 1st St, Pryor, OK 74361).</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Personal data disclosures </b></font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We may, and in certain cases must, disclose
	personal data to third parties to ensure your use of our applications
	and/or other services. The reason is that we use services of other
	companies - our partners that have limited access to certain
	information about users for purposes like administration,
	optimisation, marketing, support, sales, and payment processing. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Information received by the Company may also be
	shared with our counterparts whose services are used to improve the
	user experience and for the purposes described herein above,
	depending on the vendor.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">If you decide to use, while using our
	applications, such social media features as “like” (e.g. on
	Facebook), this will automatically enable data sharing between us and
	the relevant social platform.</font></p>
	<p style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We work with the following service providers:</font></p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">Facebook (Facebook Inc.): measurement of
		installations and conversions in application to get detailed
		information about liaison and profitability of investments stemming
		out you mobile ads;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">Google Cloud (Google LLC): server
		infrastructure, big data;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">Google Marketing Platform (Google LLC):
		composite analytic tools, marketing tools;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">Google Firebase (Google LLC): analytical
		tools, consolidated bug and failure reports, push notification tool,
		remote configuration tool;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">OVHcloud (OVH Hosting Ltd): downloadable
		content;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">Unity (Unity Technologies): analytical tools,
		remote configuration tool.</font></p>
	</li></ul>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Each of these service providers does not aim to
	collect information about the specific user through our application,
	and implements and adheres to its own privacy policy that is in
	compliance with applicable regulations, including the GDPR. If you
	want to learn more about the rules of data processing by our
	counterparts, please read their respective privacy policies.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">At the same time, we may disclose user’s data
	to third parties, including any governmental agencies, if we are
	confident that this information is required for the following
	purposes:</font></p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">legitimate demand / request of a governmental
		agency (e.g. in court proceedings);</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">revealing, investigation and/or prevention of
		fraud by users or any third parties;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">safeguarding of rights, property and/or
		security of the Company to the extent permitted by law.</font></p>
	</li></ul>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Technical, administration and other data
	protection means</b></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">To ensure the secure storage of your personal
	data, we have implemented a number of technical and administration
	tools that protect personal data against any unauthorised or unlawful
	processing and against any unintentional data loss, destruction or
	damage.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">The Company adheres the principle of data
	minimization. We process only the information related to our users /
	customers / clients that we need to perform certain functions and for
	specific purposes, or the information that you (upon your own
	consent) share with use beyond the scope of the necessary processing
	(such as when you send us your CV). </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We have set up all interfaces of our website
	and applications to provide services in a way that would ensure the
	best possible privacy.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">When we share any data with our partners, we
	always use the most safe and secure data communication channels.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">In compliance with the GDPR, the Company
	provides relevant protection for data disclosure to countries outside
	the European Economic Area or Switzerland based on standard terms and
	conditions approved by the European Commission, or any other
	applicable provisions referred to in Article 46 of the GDPR.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">However, we cannot guarantee the security of
	your data in case of any unauthorized access to your devices that are
	outside of your control, and, accordingly, the Company will not be
	liable for this.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Duration of data processing / storage </b></font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We will not keep your data longer that is
	necessary to achieve the purpose for which it is collected and
	processed, or to comply with regulatory requirements.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">In order to identify the relevant storage
	period, we identify the nature and category of the personal data,
	purposes of processing, and whether we can hit those purposes
	otherwise.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">As a general rule, the maximum duration of
	personal data processing by the Company is three (3) years after such
	data was received, but anyway as long as the user / customer is using
	our application or any other service. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">However, regulations of other countries may
	impose additional requirements, so data storage period may vary. In
	particular, if regulations of the country where our application /
	service user resides contains the limitation of action provisions
	defining the period during which you may file your claim or complaint
	against us, and we, accordingly, need relevant proof of legal
	relations with you, we may process your personal data during this
	limitation of action period.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We also need to consider any periods when we
	might need to keep your personal data for complying with our legal
	commitments to you or supervisory authorities.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Over the time, we might minimize your personal
	data that we use, or can even make them anonymous so that they are no
	longer related to you personally. In this case, we can use this
	information without further notice as it no longer contains any
	personal data.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Cookies and other tracking technologies </b></font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Cookies are small text files that are placed on
	your device such as computer or mobile device by websites that you
	visit. The website will, for a certain period of time, remember your
	preferences and actions, so that you will not have to set them up
	again. Our cookies do not identify a specific user and only identify
	the device that is being used.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Cookies and other tracking technologies on our
	website and in our applications may be used in various ways, such as
	website operation, traffic tracking, or for advertising. In
	particular, we use cookies and other tracking technologies to improve
	the quality and efficiency of our services.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">List of cookies and other tracking technologies
	used by the Company on its website is available in a separate </font><font color="#1f497d">Cookie
	Policy</font><font color="#000000">.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">To learn more about what are cookies, how they
	work, how to manage or delete them, please
	visit&nbsp;</font><a href="http://www.allaboutcookies.org/"><font color="#0070c0"><u>www.allaboutcookies.org</u></font></a></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Please note that setting of certain browsers
	allow prohibiting cookies and other tracking technologies. Please be
	aware that switching some cookies off will result into loss of
	functionality of our website or application, and, accordingly, you
	will not be able to use all of their options, and some features /
	services might not be working correctly.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Rights of personal data subjects</b></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><i><u>Rights of personal data subjects under
	the Ukrainian legislation </u></i></font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Please be advised of your rights regarding
	personal data protection and processing as enforced by the Ukrainian
	laws:</font></p>
	<ul>
		<li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">know the sources of collection and location of
		your personal data, processing purposes, location or place or
		residence of the personal data owner or manager, or direct that your
		authorized representatives receive this information unless otherwise
		governed by law;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">receive information about access to your
		personal data, including information about any third parties who
		receive your personal data;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">access to your personal data;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">within thirty days upon the relevant request,
		unless otherwise governed by law, receive a response on whether your
		personal data is processed, and receive the contents of such
		personal data; </font>
		</p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">submit a substantiated objection against
		personal data processing to the personal data owner;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000">submit a substantiated request to modify or
		delete your personal data by any personal data owner or manager,
		where such personal data is being illegally processed or is
		inaccurate;&nbsp;</font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">protection of your
		personal data against any unlawful processing and accidental loss,
		destruction or damage caused by intentional hiding, failure to
		disclose or untimely disclosure, and protection against disclosure
		of any information that is not accurate or is detrimental to honour,
		dignity and business standing of an individual;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">file complaints
		regarding your personal data processing to the competent
		administrative agency for personal data protection in Ukraine –
		</font></font><font color="#366091"><font face="Cambria, serif"><u><a href="http://www.ombudsman.gov.ua/ru/page/zpd/">Department
		for Personal Data Protection, Secretariat of the Verkhovna Rada
		Commissioner for Human Rights </a>,</u></font></font><font color="#000000"><font face="Cambria, serif">
		or to court;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">apply legal
		remedies in case of any breach of data protection regulations;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">submit statements
		of data processing limitations when consenting to such processing;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">revoke you data
		processing consent;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">know the mechanism
		of automatic data processing;</font></font></p>
		</li><li><p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
		<font color="#000000"><font face="Cambria, serif">protection against
		automated decision-making that has legal implications.</font></font></p>
	</li></ul>
	<p align="justify" style="margin-left: 1.25cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif"><i><u>Rights of
	personal data subjects under the GDPR </u></i></font></font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><font face="Cambria, serif">Please be advised
	that when you contact us you have through the identification process
	and submit your specific requirements, so that we can process your
	request and provide a response on legitimate grounds. List of data
	that we must provide to you is contained in Article <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex:32016R0679">13
	and Article 14</a>&nbsp;of the GDPR. </font></font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Please note that where we cannot identify you
	through messaging or your request to the support service, or if we
	have reasonable suspicions about your identity, we may request you to
	provide your ID. Only this way we can avoid disclosing your personal
	data with an individual claiming they are you.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We process requests as quickly as possible, but
	at the same time we ask you to remember that providing a complete and
	legitimate response regarding personal data is a complex process that
	may take up to a month or even longer. If we need more time to
	prepare a complete response, we will let you know.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">The GDPR has secured the following additional
	rights of data subjects to safeguard their personal data. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><u>The right to be informed</u></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We are ready to provide data subjects with
	information about what personal data we process. If you want to know
	what personal data we process, you can request this information at
	any time, including by contacting the Company’s data protection
	officer. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><u>The right to rectification</u></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">If you find out that some of your personal data
	that we process is incorrect or outdated, please notify us
	accordingly, including via the Company’s data protection officer. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">In certain cases, we cannot modify your
	personal data. For example, when your personal data has already been
	used in the offer and acceptance agreement and/or is contained in any
	written instrument executed and submitted to any state agency or
	otherwise according to applicable law.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><u>The right to data portability</u></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">In certain cases, you may obtain or request
	that we provide your personal data to any third party in in a
	structured, commonly used and machine-readable format.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><u>The right to restrict processing</u></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">You may request to restrict your data
	processing so that only we can keep it. This means demanding that we
	terminate any of your data processing, other than storage under
	certain circumstances. </font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><u>Revocation of data processing consent and
	the right to erasure</u></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">If we process your personal data under the
	processing consent (specifically, with the purpose of marketing
	mailings), any subsequent processing may be terminated at any time.
	It only takes to revoke your consent to such processing.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">You may also exercise your right to erasure. In
	cases referred to in Article 14 of the GDPR, the Company will delete
	the personal data being processed, other that the data that we are
	obliged to keep under applicable law.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">If you are a parent of a child under 13 (or any
	other age established by applicable law), and you believe that your
	child disclosed any information to us without your consent, please
	notify us immediately and we will remove this information.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><u>Rights in relation to automated decision
	making and profiling</u></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">The data subject shall have the right not to be
	subject to a decision based solely on automated processing, including
	profiling, which produces legal effects concerning him or her or
	similarly significantly affects him or her.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%"><a name="_gjdgxs"></a>
	<font color="#000000">We don't use personal information for the
	purposes of marketing and creating online profiles.</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><u>Complaints</u></font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">Our users residing in the EU may file a
	complaint with the European Supervisory Authority for Data
	Protection.</font></p>
	<p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000"><b>Your requests related to data protection and
	processing </b></font>
	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<font color="#000000">We have designated a data protection officer
	(DPO) who is a single point of contact for any questions or comments
	regarding your data protection and processing. You may contact DPO
	at:</font></p>
	<p align="justify" style="text-indent: 1cm; margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<a href="mailto:dpo@teachdraw.com"><font color="#0000ff"><u>dpo@teachdraw.com</u></font></a><font color="#000000">
	</font>
	</p>
	<p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
	<p align="justify" style="margin-bottom: 0cm; border: none; padding: 0cm; line-height: 100%">
	<br>

	</p>
</template>

<script>
	export default {
		name: 'PrivacyPolicy',

		data () {
			return ''
		}
	}
</script>
