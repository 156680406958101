<template>
	<div class="content-container grid-extend">
		<div class="page-title">Preparing a unique learning journey for {{ getChildName }}</div>

		<div v-if="loading" class="loader-wrap grid-extend">
			<div class="loader" :style="loaderFill">
				<svg><circle /></svg>

				{{ percent }} %
			</div>

			<div class="loading-steps">
				<div v-for="(step, index) in steps"  :class="(index == activeLoadingStep) ? 'step active' : 'step'">
					<span>{{ step }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapMutations } from 'vuex'

	export default {
		name: 'Loader',

		data () {
			return {
				percent: 0,
				minTimeout: 5000,
				maxTimeout: 7500,
				loading: true,
				activeLoadingStep: 0,
				steps: [
					'Developing growth plan',
					'Adapting plan according to age',
					'Adapting plan to yours interests',
					'Choosing the best educational games and activities'
				]
			}
		},
		mounted() {
			var _this = this,
				interv = (this.timeout() / 100),
				changeStepAfter = 100 / this.steps.length,
				load = setInterval(function(){
				_this.percent += 1

				if(_this.percent > (changeStepAfter * (_this.activeLoadingStep + 1)))
					_this.activeLoadingStep++

				if(_this.percent == 100){
					clearInterval(load)
					_this.loading = false
					_this.setStep('conclusion')
					// _this.setStep('email')
				}
			}, interv)
		},
		methods: {
			...mapMutations({
				setStep: 'questionnaire/setStep',
			}),
			timeout() {
				return Math.floor(Math.random() * (this.maxTimeout - this.minTimeout + 1) + this.maxTimeout)
			}
		},
		computed: {
			loaderFill() {
				return {
					"--percent": this.percent,
				}
			},
			...mapGetters({
				getChildName: 'questionnaire/getChildName',
				getStep: 'questionnaire/getStep',
			})
		}
	}
</script>

<style lang="scss" scoped>
	.content-container {
		--topIndent: 30px;
		--bottomIndent: 60px;
		--v_gap: 40px;

		grid-template-rows: auto 1fr;
		min-height: var(--msh);
		box-sizing: border-box;
		padding-top: var(--topIndent);
		padding-bottom: var(--bottomIndent);

		@media (min-width: 768px) {
			--bottomIndent: 80px;
		}
	}

	.loader-wrap {
		--v_gap: 40px;

		align-content: space-around;
		justify-items: center;
	}

	.loader {
		--loader_size: 238px;
		--stroke_width: 23px;
		--loader_color: var(--th_blue);
		--loader_bg: var(--th_gray_light);
		--font_size: 48px;
		--percent: 0;
		--dash_array: 675px;
		--dash_offset: calc(var(--dash_array) - (var(--dash_array) / 100 * var(--percent)));

		width: var(--loader_size);
		height: var(--loader_size);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		box-sizing: border-box;
		border: var(--stroke_width) solid var(--loader_bg);
		font-weight: bold;
		font-size: var(--font_size);
		position: relative;

		svg {
			position: absolute;
			top: calc(var(--stroke_width) * -1);
			left: calc(var(--stroke_width) * -1);
			width: var(--loader_size);
			height: var(--loader_size);
			stroke: var(--loader_color);
			fill: none;
			stroke-width: var(--stroke_width);
			stroke-dasharray: var(--dash_array);
			stroke-dashoffset: var(--dash_offset);
			transform: rotate(-90deg);
			transition: stroke-dashoffset 0.3s;

			circle {
				cx: calc(var(--loader_size) / 2);
				cy: calc(var(--loader_size) / 2);
				r: calc((var(--loader_size) / 2) - (var(--stroke_width) / 2));

			}
		}
	}

	.loading-steps {
		max-width: 260px;
		margin: 0 auto;
		font-size: 18px;
		line-height: 25px;
		text-align: center;

		.step {
			color: var(--th_gray_light);
			transition: color 0.3s;

			&.active {
				color: var(--th_blue);
			}
		}
	}
</style>