<template>
	<button class="button-extend">
		<slot></slot>
	</button>
</template>

<script>
	export default {
		name: 'my-button'
	}
</script>

<style scoped>

</style>
