<template>
	<div class="content-container grid-extend">
		<div class="introduce-content grid-extend">
			<div class="image-block">
				<img src="@/images/girl.svg" />
			</div>
			<div class="form-block">
				<h1 class="page-title">
					Help Us to Create an <span>Exciting Learning Journey</span> for your kid
				</h1>

				<form @click.prevent class="form-extend grid-extend">
					<div :class="(errorMsg) ? 'field error' : 'field'">
						<label class="label">What’s your child’s first name?</label>
						<my-input
							v-model="childName"
							placeholder="Type the name"
							v-focus
							@input="clearError"
						/>
						<span v-if="errorMsg" class="error-msg">{{ errorMsg }}</span>
						<span class="note">By continuing you agree with <span @click="showTerms">Terms of Service</span>, <span @click="showPolicy">Privacy Policy</span></span>
					</div>

					<div class="actions-block">
						<my-button
							@click="setName"
						>
							Continue
						</my-button>
					</div>

					<div class="app-links">
						<a v-if="device == 'apple'" @click="goToOneLink">
							<img src="@/images/links/app-store-logo.png" alt="">
						</a>

						<a v-if="device == 'android'" @click="goToOneLink">
							<img src="@/images/links/google-store-logo.svg" alt="">
						</a>
					</div>
				</form>
			</div>
		</div>

		<div class="introduce-footer">
			<div class="footer-links">
				<div class="item">
					<img src="@/images/footer/choose_1.svg" alt="">
				</div>

				<div class="item">
					<img src="@/images/footer/choose_2.svg" alt="">
				</div>
			</div>
		</div>

		<my-dialog v-model:show="isShowTerms">
			<template v-slot:title>
				{{ modalsData.terms.title }}
			</template>
			<template v-slot:content>
				<terms-of-service />
			</template>
		</my-dialog>

		<my-dialog v-model:show="isShowPolicy">
			<template v-slot:title>
				{{ modalsData.policy.title }}
			</template>
			<template v-slot:content>
				<privacy-policy />
			</template>
		</my-dialog>
	</div>
</template>

<script>
	import { mapGetters, mapMutations } from 'vuex'
	import { oneLinkUrl } from '../main.js'

	import TermsOfService from '@/components/terms_and_conditions/TermsOfService'
	import PrivacyPolicy from '@/components/terms_and_conditions/PrivacyPolicy'

	export default {
		name: 'Introduce',

		components: {
			TermsOfService,
			PrivacyPolicy
		},

		data () {
			return {
				oneLinkUrl: oneLinkUrl,
				isShowTerms: false,
				isShowPolicy: false,
				input: {name: 'e-mail', label: 'enter ascsa'},
				button: {name: 'lala', text: 'Continue'},
				childName: '',
				errorMsg: '',
				modalsData: {
					terms: {
						title: 'Terms of Service'
					},
					policy: {
						title: 'Privacy Policy'
					}
				}
			}
		},
		methods: {
			...mapMutations({
				setChildName: 'questionnaire/setChildName',
				setStep: 'questionnaire/setStep',
			}),
			goToOneLink() {
				location.href = oneLinkUrl
			},
			showTerms() {
				this.isShowTerms = true;
			},
			showPolicy() {
				this.isShowPolicy = true;
			},
			setName() {
				if(this.isValid(this.childName)){
					this.setChildName(this.childName)
					this.setStep('questions')
				}else{
					this.errorMsg = 'incorrect data'
				}
			},
			isValid(name){
				return /^[A-Za-zа-яА-ЯёЁ\s]+$/.test(name)
			},
			clearError(){
				this.errorMsg = ''
			}
		},
		computed: {
			...mapGetters({
				getChildName: 'questionnaire/getChildName',
				getStep: 'questionnaire/getStep',
			})
		},
		created(){
			this.childName = this.getChildName

			if (navigator.userAgent.toLowerCase().match(/(ipad|iphone)/)) {
				this.device = 'apple'
			}else{
				this.device = 'android'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content-container {
		--colCount: 1;
		--h_gap: 40px;
		--v_gap: 12px;

		min-height: var(--msh);
		grid-template-rows: 1fr auto;
		box-sizing: border-box;
		padding-top: var(--mainPadding);
		padding-bottom: var(--mainPadding);
	}

	.introduce-content {
		--colCount: 1;
		--h_gap: 40px;
		--v_gap: 12px;

		grid-template-rows: 1fr auto;
		align-items: center;
		justify-items: center;

		@media (min-width: 768px) {
			--colCount: 2;
		}

		@media (min-width: 1024px) {
			--h_gap: 140px;
		}
	}

	.image-block {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 480px) {
			max-height: 280px;
		}

		@media (max-height: 750px) {
			max-height: 220px;
		}

		@media (max-height: 670px) {
			max-height: 190px;
		}

		@media (max-height: 570px) {
			max-height: 100px;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}

	.form-block {
		width: 100%;
		max-width: 320px;
		max-height: 100%;

		@media (max-width: 480px) {
			align-self: end;
		}

		.form-extend {
			--v_gap: 20px;

			// @media (max-height: 750px) {
			@media (min-width: 768px) {
				--v_gap: 40px;
			}
		}

		.note {
			color: var(--th_gray);
			display: block;
			margin: 10px 0 0;

			span {
				cursor: pointer;
				text-decoration: underline;

				@media (hover: hover) {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.actions-block {
			.button-extend {
				width: 100%;
			}
		}
	}

	.page-title {
		--indent: 15px;

		text-align: left;
		margin: 0 0 var(--indent);

		@media (min-width: 768px) {
			--indent: 58px;
		}

		span {
			color: var(--th_blue);
		}
	}

	.app-links {
		display: grid;
		justify-content: center;

		a {
			max-width: 230px;
			display: flex;
			align-items: center;

			img {
				max-width: 100%;
			}
		}
	}

	.introduce-footer {
		display: grid;
		grid-template-columns: 100%;
		justify-content: center;
		justify-items: center;

		.footer-links {
			display: grid;
			grid-template-columns: repeat(2, auto);
			grid-gap: 24px;
			align-items: center;
		}
	}
</style>