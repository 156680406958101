<template>
	<div class="modal-wrapper" v-if="show" @click.stop="hideDialog">
		<div @click.stop class="modal-block">
			<div class="modal-header">
				<div class="title">
					<slot name="title"></slot>
				</div>
				<slot name="close">
					<span class="close-btn" @click="hideDialog">
						<img src="@/images/close.svg" alt="">
					</span>
				</slot>
			</div>
			<div class="modal-content">
				<slot name="content"></slot>
			</div>
			<div class="modal-footer">
				<slot name="footer"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	import toggleMixin from "@/mixins/toggleMixin";

	export default {
		name: 'my-dialog',
		mixins: [toggleMixin],

		mounted() {
			// console.log('dialog mounted')
		}
	}
</script>

<style lang="scss" scoped>
	.modal-wrapper {
		--sideIndent: 20px;
		--bottomIndent: 80px;

		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.5);
		position: fixed;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: var(--sideIndent) var(--sideIndent) var(--bottomIndent);
		padding-bottom: 80px;

		@media (min-width: 768px) {
			--sideIndent: 35px;
		}
	}

	.modal-block {
		--maxHeight: 490px;

		max-width: var(--contentWidth);
		max-height: var(--maxHeight);
		margin: 0 auto;
		background: white;
		border-radius: 13px;
		min-height: 50px;
		min-width: 280px;
		padding: 26px;
		box-sizing: border-box;
		position: relative;
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: auto 1fr auto;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
	}

	.modal-header {
		min-height: 25px;
		margin: 0 0 15px;

		.title {
			display: block;
			font-weight: 600;
			font-size: 18px;
			line-height: 25px;
			text-align: center;
		}

		.close-btn {
			--iconSize: 40px;

			width: var(--iconSize);
			height: var(--iconSize);
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	.modal-content {
		line-height: 1.8;
		width: calc(100% + 17px);
		box-sizing: border-box;
		padding-right: 17px;
		margin-right: -17px;
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-track {
			background-color: #E2E2E2;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #979797;
			border-radius: 5px;
		}
	}


	.modal-footer {
		&:empty {
			display: none;
		}
	}
</style>
