export const questionnaireModule = {
    state: () => ({
        device: '',
        childName: '',
        email: '',
        sex: 'default',
        age: '',
        step: 'introduce',
        langs: {
            en: {
                validate: {
                    name: 'A-Za-z'
                }
            },
            ru: {
                validate: {
                    name: 'а-яА-ЯёЁ'
                }
            }
        }
    }),
    getters: {
        getDevice(state) {
            return state.device
        },
        getChildName(state) {
            return state.childName
        },
        getStep(state) {
            return state.step
        },
        getSex(state) {
            return state.sex
        },
        getEmail(state) {
            return state.email
        },
        getAge(state) {
            return state.age
        }
    },
    mutations: {
        setDevice(state, value) {
            state.device = value;
        },
        setChildName(state, value) {
            state.childName = value;
        },
        setStep(state, step) {
            state.step = step;
        },
        setSex(state, sex) {
            state.sex = sex;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setAge(state, age) {
            state.age = age;
        }
    },
    namespaced: true
}
