<template>
	<component :is="dynamicComponent"></component>
</template>

<script>
	import { mapGetters } from 'vuex'
	import Introduce from "@/components/Introduce";
	import Questions from "@/components/Questions";
	import Loader from "@/components/Loader";
	import Email from "@/components/Email";
	import Conclusion from "@/components/Conclusion";

	export default {
		name: 'CurrentComponent',

		components: {
			Introduce,
			Questions,
			Loader,
			Email,
			Conclusion
		},

		props: {
			value: Boolean
		},
		computed: {
			dynamicComponent() {
				switch(this.getStep){
					case 'introduce':
						return 'introduce';
						break;
					case 'questions':
						return 'questions';
						break;
					case 'loader':
						return 'loader';
						break;
					case 'email':
						return 'email';
						break;
					case 'conclusion':
						return 'conclusion';
						break;
					default:
						return 'introduce';
						break;
				}
			},
			...mapGetters({
				getStep: 'questionnaire/getStep',
			})
		}
	}
</script>