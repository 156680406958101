<template>
	<div class="content-container question-wrap grid-extend">
		<div class="back-btn" @click="goBack">
			<img src="@/images/arrow-left.svg" alt="">
		</div>

		<div class="questions-list grid-extend">
			<div v-for="(question, ind) in questions" :class="(ind == currItem) ? 'questions-item grid-extend' : 'hidden-item'">
				<div class="question page-title">{{ question.quest }}</div>

				<div class="question-form grid-extend form-extend">
					<div class="answers-list grid-extend" :data-columns="getColumnsCount">
						<label v-for="answer in question.answ" :class="'answer ' + question.type + ((question.name == 'age' || question.name == 'sex') ? (' ' + question.name) : '')" @click="question.errors = false">
							<input :value="answer.val" :type="question.type" v-model="question.value" :name="question.name" @change="saveInStore" />
							<span :class="(!question.errors) ? 'icon-block' : 'icon-block error'">
								<picture v-if="answer.imgSmall">
									<source media="(min-width: 768px)" :srcset="answer.img" />
									<img :src="answer.imgSmall" alt="">
								</picture>
								<img v-else :src="answer.img" alt="">
							</span>
							<span class="text">{{ answer.text }}</span>
						</label>
					</div>

					<div class="actions-block">
						<my-button
							@click="goNext"
						>
							Continue
						</my-button>

						<div class="step-navigation">
							<div v-for="(question, ind) in questions" :class="(ind > currItem) ? 'step-item' : 'step-item active'"></div>
						</div>

						<div class="app-links">
							<a v-if="device == 'apple'" @click="goToOneLink">
								<img src="@/images/links/app-store-logo.png" alt="">
							</a>

							<a v-if="device == 'android'" @click="goToOneLink">
								<img src="@/images/links/google-store-logo.svg" alt="">
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapMutations} from 'vuex'
	import { oneLinkUrl } from '../main.js'

	export default {
		name: 'Questions',

		data () {
			return {
				oneLinkUrl: oneLinkUrl,
				childName: '',
				sex: '',
				currItem: 0,
				questions: []
			}
		},
		methods: {
			...mapMutations({
				setStep: 'questionnaire/setStep',
				setSex: 'questionnaire/setSex',
				setAge: 'questionnaire/setAge',
			}),

			goToOneLink() {
				location.href = oneLinkUrl
			},

			goNext() {
				if(this.questions[this.currItem].require){
					if(this.questions[this.currItem].value == null || !this.questions[this.currItem].value.length){
						console.log('NEED TO FIELD')
						this.questions[this.currItem].errors = true
						return
					}
				}

				if(this.questions.length > (this.currItem + 1))
					this.currItem += 1
				else
					this.setStep('loader')
			},

			goBack() {
				if(this.currItem > 0)
					this.currItem -= 1
				else
					this.setStep('introduce')
			},
			saveInStore(){
				if(this.questions[this.currItem].name == 'sex')
					this.setSex(this.questions[this.currItem].value)
				if(this.questions[this.currItem].name == 'age')
					this.setAge(this.questions[this.currItem].value)
			}
		},
		computed: {
			...mapGetters({
			  	getChildName: 'questionnaire/getChildName',
			  	getStep: 'questionnaire/getStep',
			  	getSex: 'questionnaire/getSex',
			  	getAge: 'questionnaire/getAge',
			}),
			getColumnsCount: function(){
				return this.questions[this.currItem].answ.length / 2
			}
		},
		created(){
			this.childName = this.getChildName
			this.questions = [
				{
					quest: this.childName + ' is a ...',
					name: 'sex',
					type: 'radio',
					value: null,
					require: true,
					errors: false,
					answ: [
						{
							text: 'Boy',
							val: 'boy',
							img: require('../images/questions/sex/boy.svg'),
							imgSmall: require('../images/questions/sex/mob/boy.svg')
						},
						{
							text: 'Girl',
							val: 'girl',
							img: require('../images/questions/sex/girl.svg'),
							imgSmall: require('../images/questions/sex/mob/girl.svg')
						}
					]
				},
				{
					quest: 'How old is ' + this.childName + '?',
					name: 'age',
					type: 'radio',
					value: null,
					require: true,
					errors: false,
					answ: [
						{
							text: '0-2',
							val: '0-2',
							img: require('../images/questions/age/0-2.svg')
						},
						{
							text: '3-4',
							val: '3-4',
							img: require('../images/questions/age/3-4.svg')
						},
						{
							text: '5-6',
							val: '5-6',
							img: require('../images/questions/age/5-6.svg')
						},
						{
							text: '6+',
							val: '6+',
							img: require('../images/questions/age/6+.svg')
						}
					]
				},
				{
					quest: 'What would you like ' + this.childName + ' to do?',
					name: 'like_to_do',
					type: 'checkbox',
					value: [],
					answ: [
						{
							text: 'Learn ABC',
							val: 'Learn ABC',
							img: require('../images/questions/like_to_do/1.svg')
						},
						{
							text: 'Boost creativity and imagination',
							val: 'Boost creativity and imagination',
							img: require('../images/questions/like_to_do/2.svg')
						},
						{
							text: 'Master numbers and math',
							val: 'Master numbers and math',
							img: require('../images/questions/like_to_do/3.svg')
						},
						{
							text: 'Refine Memory and attention',
							val: 'Refine Memory and attention',
							img: require('../images/questions/like_to_do/4.svg')
						},
						{
							text: 'Cognitive reasoning skills',
							val: 'Cognitive reasoning skills',
							img: require('../images/questions/like_to_do/5.svg')
						},
						{
							text: 'Learn shapes and colors',
							val: 'Learn shapes and colors',
							img: require('../images/questions/like_to_do/6.svg')
						},
						{
							text: 'Practice writing',
							val: 'Practice writing',
							img: require('../images/questions/like_to_do/7.svg')
						},
						{
							text: 'Solve Puzzles',
							val: 'Solve Puzzles',
							img: require('../images/questions/like_to_do/8.svg')
						}
					]
				},
				{
					quest: 'What would you like ' + this.childName + ' to do?',
					name: 'what_like',
					type: 'checkbox',
					value: [],
					answ: [
						{
							text: 'Hide & seek',
							val: 'Hide & seek',
							img: require('../images/questions/what_like/1.svg')
						},
						{
							text: 'Playing with characters',
							val: 'Playing with characters',
							img: require('../images/questions/what_like/2.svg')
						},
						{
							text: 'Dress-up games',
							val: 'Dress-up games',
							img: require('../images/questions/what_like/3.png')
						},
						{
							text: 'Cooking games',
							val: 'Cooking games',
							img: require('../images/questions/what_like/4.svg')
						},
						{
							text: 'Puzzles & photofits',
							val: 'Puzzles & photofits',
							img: require('../images/questions/what_like/5.svg')
						},
						{
							text: 'Shopping games',
							val: 'Shopping games',
							img: require('../images/questions/what_like/6.svg')
						}
					]
				}
			]

			if (navigator.userAgent.toLowerCase().match(/(ipad|iphone)/)) {
				this.device = 'apple'
			}else{
				this.device = 'android'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content-container {
		--topIndent: 30px;
		--bottomIndent: 30px;
		--v_gap: 40px;

		min-height: var(--msh);
		box-sizing: border-box;
		padding-top: var(--topIndent);
		padding-bottom: var(--bottomIndent);

		@media (min-width: 768px) {
			--bottomIndent: 80px;
		}

		@media (max-width: 767px) {
			height: var(--msh);
			overflow: hidden;
		}
	}

	.questions-list {
		grid-template-rows: 1fr;

		overflow: hidden;
	}

	.questions-item {
		--v_gap: 35px;

		grid-template-rows: auto 1fr;
		height: 100%;
		overflow: hidden;
	}

	.hidden-item {
		display: none;
	}

	.question-form {
		--v_gap: 40px;

		align-content: space-around;
		justify-items: center;

		@media (max-height: 750px) {
			--v_gap: 20px;
		}

		@media (max-width: 767px) {
			height: 100%;
			overflow: hidden;
		}
	}

	.answers-list {
		--columnsCount: 5;
		--itemWidth: 100px;
		--itemHeight: var(--itemWidth);
		--h_gap: 50px;
		--v_gap: 10px;

		grid-template-columns: repeat(var(--columnsCount), auto);
		justify-content: center;
		justify-items: center;

		@media (max-width: 767px) {
			grid-template-columns: repeat(var(--columnsCount), minmax(auto, calc( ( 100% - ( ( var(--columnsCount) - 1 ) * var(--h_gap) ) ) / var(--columnsCount) )));

			width: 100%;
			box-sizing: border-box;
			padding-left: calc((100% - (var(--itemWidth) * var(--columnsCount) + var(--h_gap))) / 2);
			padding-right: calc((100% - (var(--itemWidth) * var(--columnsCount) + var(--h_gap))) / 2);

			overflow-y: auto;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				width: 3px;
			}

			&::-webkit-scrollbar-track {
				background-color: transparent;
				border-radius: 3px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--th_blue);
				border-radius: 3px;
			}
		}


		&[data-columns="1"],
		&[data-columns="2"] {
			--columnsCount: 2;
		}

		&[data-columns="3"] {
			--columnsCount: 3;
		}

		&[data-columns="4"] {
			--columnsCount: 4;
		}

		&[data-columns="1"] {
			--itemWidth: 160px;
			--itemHeight: auto;
			--h_gap: 50px;

			width: 100%;

			@media (min-width: 768px) {
				--h_gap: 100px;
			}
		}

		&[data-columns="2"] {
			--columnsCount: 2;

			--itemWidth: 120px;
			--h_gap: 60px;
			--v_gap: 15px;
		}

		&,
		&[data-columns="3"],
		&[data-columns="4"] {
			@media (max-width: 767px) {
				--columnsCount: 3;
			}

			@media (max-width: 480px) {
				--columnsCount: 2;
			}
		}
	}

	.answer {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		position: relative;

		&.sex {
			.text {
				--fontWeight: bold;
				--fontSize: 24px;
				--lineHeight: 33px;

				@media (min-width: 768px) {
					--fontSize: 22px;
					--lineHeight: 30px;
				}
			}
		}

		&.age {
			height: 100%;

			.icon-block {
				height: calc( ( ( var(--msh) - 300px ) - 73px - 95px - var(--v_gap) ) / 2);
				flex-grow: 1;
			}

			.text {
				--minHeight: auto;
				--fontWeight: bold;
				--fontSize: 24px;
				--lineHeight: 33px;

				@media (min-width: 768px) {
					--fontSize: 18px;
					--lineHeight: 25px;
				}
			}
		}

		picture {
			display: flex;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}

		input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
		}

		.icon-block {
			--shadowWidth: 0px;
			--lineColor: var(--th_gray_light);

			max-width: 100%;
			width: var(--itemWidth);
			height: var(--itemHeight);
			display: flex;
			align-items: center;
			justify-content: center;

			&.error {
				--lineColor: red;
			}
		}

		.text {
			--minHeight: 40px;
			--fontWeight: 600;
			--fontSize: 16px;
			--lineHeight: 22px;
			--textColor: #313131;

			flex-shrink: 0;
			min-height: var(--minHeight);
			display: flex;
			justify-content: center;
			font-weight: var(--fontWeight);
			font-size: var(--fontSize);
			line-height: var(--lineHeight);
			text-align: center;
			color: var(--textColor);
			transition: color 0.3s;

			@media (min-width: 768px) {
				--fontWeight: normal;
				--fontSize: 14px;
				--lineHeight: 19px;
				--textColor: var(--th_gray);
			}
		}

		&.checkbox {
			.icon-block {
				border: 1.5px solid var(--th_gray_light);
				box-sizing: border-box;
				border-radius: 8px;
				box-shadow: inset 0 0 0 var(--shadowWidth) var(--th_blue);
				transition: box-shadow 0.3s;
				padding: 5px;
			}

			.text {
				width: var(--itemWidth);
				min-width: 120px;
				// max-width: var(--itemWidth);
			}
		}

		&.radio {
			.icon-block {
				position: relative;
				margin: 0 0 30px;

				&:after {
					content: '';
					position: absolute;
					left: calc(50% - 50px);
					bottom: -15px;
					width: 100px;
					height: 6px;
					border-radius: 3px;
					background: var(--lineColor);
					transition: background 0.3s;
				}
			}

			.text {
				text-align: center;
			}
		}

		input:checked {
			~ .icon-block {
				--shadowWidth: 4px;
				--lineColor: var(--th_blue);
			}

			~ .text {
				--textColor: var(--th_blue);
			}
		}
	}

	.actions-block {
		width: 100%;
		max-width: 320px;
		display: grid;
		grid-template-columns: 100%;
		grid-gap: 20px;

		.button-extend {
			width: 100%;
		}

		.step-navigation {
			--itemWidth: 30px;
			--itemHeight: 3px;
			--itemBg: var(--th_gray_light);

			display: grid;
			grid-template-columns: repeat(auto-fit, var(--itemWidth));
			grid-gap: 3px;
			justify-content: center;

			.step-item {
				width: var(--itemWidth);
				height: var(--itemHeight);
				border-radius: var(--itemHeight);
				background: var(--itemBg);

				&:first-child {
					--itemBg: var(--th_blue);
				}

				&.active {
					--itemBg: var(--th_blue);
				}
			}
		}
	}

	.app-links {
		display: grid;
		justify-content: center;

		a {
			max-width: 230px;
			display: flex;
			align-items: center;

			img {
				max-width: 100%;
			}
		}
	}
</style>