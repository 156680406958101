<template>
	<p lang="ru-RU" class="western" style="margin-top: 0.13cm; margin-bottom: 0.13cm; line-height: 1.01cm; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>TERMS
	OF USE</b></span></font></font></p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Effective
	Date (last updated): [</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><i>Date</i></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">]&nbsp;</span></font></font></p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol>
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>ACCEPTANCE
		OF TERMS<br>
	</b></u></span></font></font><br>

		</p>
	</li></ol>
	<p lang="ru-RU" align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Please
	read this Terns of Use carefully before using the </span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">_________________</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">
	website operated by Teach&amp;Draw Limited, a company incorporated
	under the laws of the Republic of Cyprus, registered address:
	Makariou III, 22 Makaria Center, 4th floor, office 403, 6016 Larnaca,
	Cyprus, registration number: HE385979.</span></font></font></p>
	<p align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">These
	Terms of Use apply to all visitors, users and others who access or
	use the Services (as they are described below).</span></font></font></p>
	<p lang="ru-RU" class="western" align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Teach&amp;Draw
	Limited (“Company,” “we,” “us,” “our”) provides its
	Services to you subject to your agreement with these Terms of Use
	(“ToU”). This document describes in details your rights and our
	rights relating to the provision of our Services, so please review
	the ToU carefully. </span></font></font>
	</p>
	<p class="western" align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">We
	may update this ToU from time to time by posting a new version on our
	website. We will notify you about significant modifications where it
	is possible and necessary via email provided by you, however please
	be advised that we are not obliged to do so. Such modifications shall
	be effective upon our posting of the revised ToU on our website. It
	is therefore important that you review these ToU regularly to ensure
	you are updated as to any changes. Your continued use of the Services
	after any such update constitutes your binding acceptance of such
	changes. If you do not agree to be bound by this ToU, you should
	discontinue all use of the Services immediately. </span></font></font>
	</p>
	<p class="western" align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">If
	you register with our website or buy any product or Services (or any
	portion thereof) from our website, we may ask you to expressly agree
	to these ToU or confirm that you have read it and have proceeded with
	using our Services knowingly.</span></font></font></p>
	<p style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-left: 0.16cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Please
	see also our Privacy Policy, our Cookies Policy, which are not part
	of this agreement.</span></font></font></p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="2">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>DESCRIPTION
		OF SERVICES<br>
	</b></u></span></font></font><br>

		</p>
	</li></ol>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">We
	offer our users the opportunity to register for email updates,
	browse, use our apps via our website or download them on your device,
	share notes and similar material as part of our apps and enjoy other
	rich content on our website (collectively, the “Services”).
	Unless explicitly stated otherwise, any new features that augment or
	enhance the current Services shall be subject to this ToU.</span></font></font></p>
	<p align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">To
	use our Services, you will need a device that meets certain system
	and compatibility requirements. Apps available on or through our
	website may have additional system requirements. Please note that
	Services may only be available for certain operating systems (such as
	iOS, Android, Windows OS or Windows 8). You may only use Services
	(including download and use the apps) on a device running validly
	licensed copies of the operating systems on which Services were
	designed to operate. To be able to access and/or use the Services, or
	any portion thereof, you must legally obtain all the applicable or
	required facilities, utilities, software and equipment at his/her
	sole risk and expense.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">The
	availability of apps and features may vary between countries and not
	all of them may be available in your country.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Unless
	we have agreed a particular level of service with you, we make
	absolutely no promises about the quality or existence of any of our
	Services. Please read the sections below and our general exclusion of
	liability.</span></font></font></p>
	<p align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Some
	of our activities are carried out on web platforms provided by third
	parties. For example App Store (</span></font></font><font color="#0563c1"><u><a href="https://www.apple.com/app-store/"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">https://www.apple.com/app-store/</span></font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">)
	or Google Play (https://play.google.com/store). If you make use of
	any Service where that is the case, you are responsible for complying
	with any terms of service of the third party platform.</span></font></font></p>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="3">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>YOUR
		REGISTRATION OBLIGATIONS<br>
	</b></u></span></font></font><br>

		</p>
	</li></ol>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0.28cm; line-height: 108%">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Before
	you can use our Services, you may need to register for an account
	(“Account”). In order to create an Account, you must:</span></font></font></p>
	<p align="justify" style="margin-bottom: 0.28cm; line-height: 108%"><br>
	<br>

	</p>
	<ul>
		<li><p lang="ru-RU" style="margin-bottom: 0.28cm; line-height: 108%">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">be
		at least 13 years old (unless different age limitations apply in
		your country). We may require and adequate evidence of such consent
		at any time.; and</span></font></font></p>
		</li><li><p lang="ru-RU" style="margin-bottom: 0.28cm; line-height: 108%">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">be
		legally permitted to use the Services by the Laws of your home
		country.</span></font></font></p>
	</li></ul>
	<p style="margin-left: 1.27cm; margin-bottom: 0.28cm; line-height: 108%">
	<br>
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">If
	the user is minor, he/she shall become acquainted of these ToU with
	the help of his or her parents/legal guardians. To protect children’s
	privacy, we advise parents or legal guardians to check that their
	children never disclose their personal data without prior consent of
	their parents or guardians. We reserve the right to restrict access
	to certain Services on age grounds and may allow the registration for
	certain Services with parental approval when underage. We reserve the
	right to ask for written proof of parental consent for any user or
	potential user of our Services suspected to be a minor. In all cases,
	use of the Services by minors must take place under the
	responsibility of their parents or legal guardians and any use of the
	Services is assumed to have been validated by them.</span></font></font></p>
	<p align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	can create an Account via manual registration or we may provide you
	with option to register via your account on third party platform
	(e.g. Facebook account, Google account etc). </span></font></font>
	</p>
	<p style="margin-bottom: 0.28cm; line-height: 108%"><br>
	<br>

	</p>
	<p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">By
	creating an Account, you agree:</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.11cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	to provide accurate, current and complete information about yourself
	as prompted (“Registration Data”), maintain and promptly update
	the Registration Data to keep it accurate, current and complete. You
	must supply us with a valid e-mail address. If you provide any
	information that is inaccurate, not current or incomplete, or we have
	reasonable grounds to suspect that such information is inaccurate,
	not current or incomplete, we may deny you access to areas requiring
	registration, at our sole discretion;</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.11cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	that you are responsible for the security of your accounts and making
	sure that any contact details in the account are kept up to date. If
	we need to contact you but are unable to do so, for example because
	your e-mail address is no longer valid, then any consequences of that
	failure will be your responsibility;</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.11cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	that you must not let anyone else use your Account. You must let us
	know of any unauthorized use of your Account as soon as you are able
	to after becoming aware of it;</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.11cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	that unless an Account is associated with paid-for Services, we may
	suspend or terminate it at any time. Equally, you may close your
	Account at any time. </span></font></font>
	</p>
	<p align="justify" style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">As
	a result of your registration for the Services, you may receive
	certain communications from us. You understand and agree that these
	communications are part of your registration.</span></font></font></p>
	<p align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	acknowledge, consent and agree that we may access, preserve and
	disclose your Account information and content if required to do so by
	law or in a good faith belief that such access, preservation, or
	disclosure is reasonably necessary: (i) to comply with legal process;
	(ii) to enforce the ToU; (iii) to respond to claims that any content
	violates the rights of third parties; (iv) to respond to your
	requests for customer service; or (v) to protect the rights,
	property, or personal safety of us, our users and the public.</span></font></font></p>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="4">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>PRIVACY
		POLICY</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Your
	Registration Data, as well as information that you may provide at
	other times during your use of the Services, is subject to our
	</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Privacy
	Policy</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">.
	Please check it out for more information about data we use and how we
	use it. </span></font></font>
	</p>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="5">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>PAYMENT
		AND BILLING</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">We
	may allow you to download, view or use our apps or other Services
	free of charge. And some our Services may be available for a fee
	(whether by way of in-app purchases or by way of subscription fees),
	as further described on our application detail pages. Additional
	restrictions may apply to access to and use of certain free Services.
	Pricing and availability of all Services provided by us are subject
	to change at any time prior to purchase.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">All
	fees are based on Services purchased, regardless of actual usage.
	Please note that we do not provide price protection or refunds for
	existing subscribers in the event of a price drop or promotional
	offering for new subscribers.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	must either use a credit card or other payment method accepted by us
	(e.g., Credit Card, Apple’s iTunes App Store, Google Play, PayPal)
	to activate and maintain your paid-for Services.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">By
	providing a payment method, you expressly authorize us to charge the
	applicable fees through the payment method that you use when
	registering for an Account. You will also be responsible for charges
	for any products or services that you order that are offered for sale
	through the Services.</span></font></font></p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	may terminate a paid-for Services at any time by giving us notice of
	termination. Any payment you have already made to us will not be
	repayable, but we will continue to supply the Services — unless you
	ask us otherwise — until the next payment date.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">We
	may suspend a paid-for Services if we reasonably believe:</span></font></font></p>
	<ul>
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">It
		is necessary for us to do so in order to prevent any unlawful
		activity;</span></font></font></p>
		</li><li><p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
		have breached this agreement in a sufficiently serious way to
		justify suspending the service in order to prevent that breach;</span></font></font></p>
		</li><li><p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
		have failed to pay us any sum of money you owe.</span></font></font></p>
	</li></ul>
	<p align="justify" style="margin-left: 1.91cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">If
	we do suspend a service for any of the above reasons, we will take
	all reasonable steps to inform you promptly of what we have done and
	we will lift the suspension as soon as we are satisfied that the
	reason for the suspension no longer applies.</span></font></font></p>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="6">
		<li><p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>USER
		CONDUCT</b></u></span></font></font></p>
	</li></ol>
	<p align="justify" style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">By
	using the Services, it is your responsibility to know, understand and
	abide by our rules of use. These rules are not meant to be
	exhaustive, and we reserve the right to determine what types of
	conduct it considers to be inappropriate use of the Service and to
	take such measures as it sees fit. We reserves the right to add to or
	amend this list of rules at any time.</span></font></font></p>
	<p align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	agree that you will not use the Services to:</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	interfere with or disrupt the Services or servers or networks
	connected to the Services, or disobey any requirements, procedures,
	policies or regulations of networks connected to the Services.</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	intentionally or unintentionally violate any applicable local, state,
	national or international law.</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	use the Services for non-personal or commercial purposes without our
	express prior written consent.</span></font></font></p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	copy, modify, alter, adapt, make available, translate, port, reverse
	engineer, decompile, or disassemble any portion of the content made
	accessible by us on or through the website, including any
	information, videos, text, graphics, software programs used by us in
	connection with the website, Services or our apps, materials,
	descriptions, data obtained from or through the website
	(collectively, “Content”), or publicly display, reproduce, create
	derivative works from, perform, distribute, or otherwise use such
	Content, other than as permitted under these Terms; </span></font></font>
	</p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	copy, distribute, display, execute publicly, make available to the
	public, reduce to human readable form, decompile, disassemble, adapt,
	sublicense, make any commercial use, sell, rent, transfer, lend,
	process, compile, reverse engineer, combine with other software,
	translate, modify or create derivative works of any material that is
	subject to our proprietary rights, including our Intellectual
	Property, in any way or by any means, unless expressly permitted in
	the Terms; </span></font></font>
	</p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	make any use of the Content on any other site or networked computer
	environment for any purpose, or replicate or copy the Content without
	our prior written consent; </span></font></font>
	</p>
	<p lang="ru-RU" align="justify" style="text-indent: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">-
	infringe and/or violate any of the Terms.</span></font></font></p>
	<p align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Violation
	of our rules may result in the canceling of your account.</span></font></font></p>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="7">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>INDEMNITY</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	agree to indemnify, defend, and hold us and our subsidiaries,
	affiliates, officers, employees, agents, co-branders and other
	partners, harmless from any claim or demand, including reasonable
	attorneys’ fees, made by any third party due to or arising out of
	your use of the Service, any transaction resulting from use of the
	Service, your connection to the Service, your violation of these
	Terms, and/or your violation of any rights of another.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="8">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>TERMINATION</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	agree that we may, in its sole and absolute discretion and without
	notice or liability to you or any third party, immediately terminate
	your access to the Services. Grounds for such termination shall
	include, but not be limited to, (a) breaches or violations of the ToU
	or other agreements or guidelines, (b) requests by law enforcement or
	government agencies, (c) a request by you (self-initiated account
	deletions), (d) discontinuance or material modification of the
	Service (or any part thereof), (e) unexpected technical or security
	issues or problems, and (f) extended periods of inactivity. </span></font></font>
	</p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Termination
	of your Account(s) may include (a) removal of access to all offerings
	within the Services, and (b) barring further use of the Services.</span></font></font></p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="9">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>DEALINGS
		WITH ADVERTISERS AND OTHER USERS</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Your
	correspondence or business dealings with, or participation in
	promotions of, advertisers or third parties found on or through the
	Services, such as through banner ads or hyperlinks displayed on the
	Services, including payment for and delivery of related goods or
	services, and any other terms, conditions, warranties or
	representations associated with such dealings, are solely between you
	and such third party. You agree that we shall not be responsible or
	liable for any loss or damage of any sort incurred as the result of
	any such dealings or as the result of the presence of such
	advertisers or third parties on the Services.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="10">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>LINKS</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">The
	Services may provide links to other World Wide Web sites or
	resources. Because we have no control over such sites and resources,
	you acknowledge and agree that we are not responsible for the
	availability of such external sites or resources, and do not endorse
	and are not responsible or liable for any content, advertising,
	products, or other materials on or available from such sites or
	resources. You further acknowledge and agree that we shall not be
	responsible or liable, directly or indirectly, for any damage or loss
	caused or alleged to be caused by or in connection with use of or
	reliance on any such content, goods or services available on or
	through any such site or resource.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="11">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>OUR
		PROPRIETARY RIGHTS</b></u></span></font></font></p>
	</li></ol>
	<p class="western" style="text-indent: 1.75cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">All
	text, graphics, user interfaces, visual interfaces, photographs,
	trademarks, logos, sounds, music, artwork and computer code
	(collectively, "Content"), including but not limited to the
	design, structure, selection, coordination, expression, "look
	and feel" and arrangement of such Content, contained on the
	website is owned, controlled or licensed by or to Teach&amp;Draw
	Limited, and is protected by trade dress, copyright, patent and
	trademark laws, and various other intellectual property rights and
	unfair competition laws.</span></font></font></p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	acknowledge and agree that the Services contains proprietary and
	confidential information that is protected by applicable intellectual
	property and other laws. </span></font></font>
	</p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="12">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>DISCLAIMER
		OF WARRANTIES</b></u></span></font></font></p>
	</li></ol>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	expressly understand and agree that:</span></font></font></p>
	<ul>
		<li><p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Your
		use of the Services is at your sole risk. The Services is provided
		on an “AS IS” and “as available” basis. We expressly
		disclaim all warranties of any kind, whether express or implied,
		including but not limited to the implied warranties of
		merchantability, fitness for a particular purpose, accuracy, and
		non-infringement. We assume no responsibility for the timeliness,
		deletion, mis-delivery or failure to provide any content.</span></font></font></p>
		</li><li><p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Any
		content downloaded or otherwise obtained through the Services is
		downloaded and used at your sole discretion and risk and you will be
		solely responsible for any damage to your computer system or loss of
		data that results form the download or use of any such content.</span></font></font></p>
		</li><li><p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">No
		advice or information, whether oral or written, obtained by you from
		us or through or from the Services shall create any warranty not
		expressly stated in the ToU.</span></font></font></p>
	</li></ul>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="13">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>LIMITATION
		ON LIABILITY</b></u></span></font></font></p>
	</li></ol>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">YOU
	UNDERSTAND AND AGREE THAT&nbsp;WE SHALL NOT BE LIABLE TO YOU FOR ANY
	DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
	DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
	GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAD BEEN
	ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE
	USE OR THE INABILITY TO USE THE SERVICES; (ii) UNAUTHORIZED ACCESS TO
	OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iii) STATEMENTS OR
	CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (iv) ANY OTHER MATTER
	RELATING TO THE SERVICE. NOTWITHSTANDING ANYTHING TO THE CONTRARY
	CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
	REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
	THE GREATER OF ONE HUNDRED U.S. DOLLARS (USD 100) OR THE AMOUNT PAID,
	IF ANY, BY YOU TO US DURING THE PAST SIX MONTHS FOR THE SERVICES
	GIVING RISE TO THE CLAIM.</span></font></font></p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="14">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>EXCLUSIONS
		AND LIMITATIONS</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Some
	jurisdictions do not allow the exclusion of certain warranties or the
	limitation or exclusion of liability for incidental or consequential
	damages. Accordingly, some of the above limitations may not apply to
	you.</span></font></font></p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="15">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>NO
		THIRD PARTY BENEFICIARIES</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">You
	agree that, except as otherwise expressly provided in this ToU, there
	shall be no third party beneficiaries to this Agreement.</span></font></font></p>
	<p class="western" style="text-indent: 2.06cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="16">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>NOTICE</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">We
	may provide you with notices, including those regarding changes to
	the ToU, by email, regular mail, or postings on the Services;
	provided, however, that this Section places no requirements us not
	already expressly set forth herein.</span></font></font></p>
	<p class="western" style="text-indent: 1.91cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="17">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>TRADEMARK
		INFORMATION</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Company’s
	and other names, logos and marks are the trademarks and service marks
	of Teach&amp;Draw Limited or its Licensors or Affiliates (the
	“Company Marks”). You agree that you will not display the Company
	Marks, or use the Company Marks in any manner, without the prior
	written permission of the Company.</span></font></font></p>
	<p class="western" style="text-indent: 1.91cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="18">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>DISPUTES</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Any
	dispute arising out of or related to the Services or the ToU will be
	brought in, and you hereby consent to exclusive jurisdiction and
	venue in, the competent courts of the territory we base. You agree to
	waive all defenses of lack of personal jurisdiction and forum
	non-convenience and agree that process may be served in a manner
	authorized by applicable law or court rule. Notwithstanding the
	foregoing, we may seek injunctive relief in any court of competent
	jurisdiction.</span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">
	</span></font></font>
	</p>
	<p class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="19">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>GENERAL
		INFORMATION</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>Entire
	Agreement.</b></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">
	The ToU constitutes the entire agreement between you and us with
	respect to the Services and supersedes any prior agreements, oral or
	written, between you and us.</span></font></font></p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>Waiver
	and Severability of Terms</b></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">.
	Our failure to exercise or enforce any right or provision of the ToU
	shall not constitute a waiver of such right or provision. If any
	provision of the ToU is found by a court of competent jurisdiction to
	be invalid, the parties nevertheless agree that the court should
	endeavor to give effect to the parties’ intentions as reflected in
	the provision, and the other provisions of the ToU remain in full
	force and effect.</span></font></font></p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>Statute
	of Limitations</b></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">.
	You agree that regardless of any statute or law to the contrary, any
	claim or cause of action arising out of or related to the use of the
	Services or the ToU must be filed within one (1) year after such
	claim or cause of action arose or be forever barred.</span></font></font></p>
	<p lang="ru-RU" class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><b>Section
	Titles.</b></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">
	The section titles in the ToU are for convenience only and have no
	legal or contractual effect.</span></font></font></p>
	<p class="western" align="justify" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<ol start="20">
		<li><p lang="ru-RU" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
		<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US"><u><b>VIOLATIONS</b></u></span></font></font></p>
	</li></ol>
	<p style="margin-left: 1.27cm; margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<br>

	</p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0cm; line-height: 100%; background: #ffffff">
	<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">Please
	report any violations of the ToU to </span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="en-US">legal@teachdraw.com</span></font></font></p>
	<p lang="ru-RU" class="western" style="margin-bottom: 0.28cm; line-height: 108%">
	<br>
	<br>

	</p>
</template>

<script>
	export default {
		name: 'TermsOfService',

		data () {
			return ''
		}
	}
</script>
