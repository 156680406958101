import { createApp } from 'vue'
import App from './App'
import components from '@/components/UI';
import directives from '@/directives';
import store from '@/store';

import '@/scss/styles.scss'

// appsflyer -----------------
import './scripts/onelink-smart-script'

function getCookie(name) {
	const parts = `; ${document.cookie}`.split(`; ${name}=`)
	if (parts.length === 2) {
		return parts.pop().split(';').shift()
	}
}

const onelinkGenerator = new window.AF.OneLinkUrlGenerator({
	oneLinkURL: "https://ybw.onelink.me/l0wM",
	pidKeysList: ['utm_source'],
	campaignKeysList: ['af_pid', 'utm_campaign'],
	campaignStaticValue: 'organic',
	pidStaticValue: 'onboarding',
	skipList: [],
	fbcidParam: 'af_sub5'
});

var fbc = getCookie("_fbc");
if (fbc) {
	onelinkGenerator.setAfSub5("fbclid", fbc);
} else {
	onelinkGenerator.setAfSub5("fbclid");
}

onelinkGenerator.setAdset("utm_adset")
onelinkGenerator.setAfSub1("fbp", getCookie('_fbp'))


export const oneLinkUrl = onelinkGenerator.generateUrl();
// ===========================



// firebase ------------------
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyCdSOfnqfden-nNTnoB3q1BLDIJHP14aiI",
	authDomain: "bini-yummies-web-onboarding.firebaseapp.com",
	projectId: "bini-yummies-web-onboarding",
	storageBucket: "bini-yummies-web-onboarding.appspot.com",
	messagingSenderId: "272483135409",
	appId: "1:272483135409:web:09d05310f96419d2e45790",
	measurementId: "G-X3W3M5GNJZ"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// ===========================



const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})

directives.forEach(directive => {
    app.directive(directive.name, directive)
})


app
    .use(store)
    .mount('#app');
