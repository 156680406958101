<template>
	<div class="content-container grid-extend">
		<div class="email-content grid-extend">
			<div class="back-btn" @click="goBack">
				<img src="@/images/arrow-left.svg" alt="">
			</div>

			<div class="page-title">Please enter your email to get a Learning Pack for {{ getChildName }}</div>

			<div class="form-block grid-extend">
				<div class="image-block">
					<img v-if="sex == 'boy'" src="@/images/email/boy.svg" />
					<img v-else src="@/images/email/girl.svg" />
				</div>

				<form @click.prevent class="form-extend grid-extend">
					<div :class="(errorMsg) ? 'field error' : 'field'">
						<label class="label">We'll send you an email with your personalized Learning Pack</label>
						<my-input
							v-model="email"
							placeholder="Type the e-mail"
							v-focus
							@input="clearError"
						/>
						<span v-if="errorMsg" class="error-msg">{{ errorMsg }}</span>
					</div>

					<div class="actions-block">
						<my-button
							@click="Continue"
						>
							Continue
						</my-button>
					</div>
				</form>
			</div>
		</div>
		<div class="email-footer">
			<div class="footer-links">
				<div class="item">
					<img src="@/images/footer/choose_1.svg" alt="">
				</div>

				<div class="item">
					<img src="@/images/footer/choose_2.svg" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MyButton from "@/components/UI/MyButton";
	import { mapGetters, mapMutations } from 'vuex'

	export default {
		name: 'Email',

		data () {
			return {
				email: '',
				input: {name: 'e-mail', label: 'enter ascsa'},
				button: {name: 'lala', text: 'Continue'},
				errorMsg: '',
			}
		},
		methods: {
			...mapMutations({
				setChildName: 'questionnaire/setChildName',
				setStep: 'questionnaire/setStep',
				setEmail: 'questionnaire/setEmail',
			}),
			Continue() {
				if(this.isValid(this.email)){
					this.setEmail(this.email)
					this.setStep('conclusion')
				}else{
					this.errorMsg = 'incorrect data'
				}
			},
			goBack() {
				this.setStep('questions')
			},
			isValid(name){
				return /\S+@\S+\.\S+/.test(name)
			},
			clearError(){
				this.errorMsg = ''
			}


		},
		computed: {
			...mapGetters({
			  	getChildName: 'questionnaire/getChildName',
			  	getStep: 'questionnaire/getStep',
			  	getSex: 'questionnaire/getSex',
			})
		},
		created(){
			this.sex = this.getSex
		}
	}
</script>

<style lang="scss" scoped>
	.content-container {
		--colCount: 1;
		--h_gap: 40px;
		--v_gap: 12px;

		min-height: var(--msh);
		grid-template-rows: 1fr auto;
		justify-items: center;
		box-sizing: border-box;
		padding-top: var(--mainPadding);
		padding-bottom: var(--mainPadding);
	}

	.email-content {
		--topIndent: 30px;
		--bottomIndent: 60px;
		--v_gap: 40px;

		grid-template-rows: auto 1fr;

		@media (min-width: 768px) {
			--bottomIndent: 80px;
		}
	}

	.form-block {
		--v_gap: 40px;

		align-content: space-around;
		justify-items: center;

		@media (min-width: 768px) {
			align-content: center;
		}

		.image-block {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.form-extend {
			--v_gap: 40px;

			width: 100%;
			max-width: 320px;
		}

		.actions-block {
			.button-extend {
				width: 100%;
			}
		}
	}

	.email-footer {
		display: grid;
		grid-template-columns: 100%;
		justify-content: center;
		justify-items: center;

		.footer-links {
			display: grid;
			grid-template-columns: repeat(2, auto);
			grid-gap: 24px;
			align-items: center;
		}
	}
</style>