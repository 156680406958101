<template>
	<div class="content-container grid-extend">
		<div class="conclusion-header">
			<div class="header-links">
				<img src="@/images/conclusion/header/add-of-day.svg" alt="">
				<img src="@/images/conclusion/header/new-app.svg" alt="">
			</div>
			<div class="logo">
				<img src="@/images/conclusion/header/logo.svg" alt="">
			</div>
		</div>

		<div class="conclusion-content">
			<div class="page-title">
				recommended for you
			</div>

			<div class="swipe-container">
				<div class="swipe-wrapper">
					<div class="swipe-block recommended-apps grid-extend">
						<div class="item">
							<div class="image">
								<img style="top: -11px; left: -3px;" src="@/images/conclusion/items/1.svg" alt="">
							</div>
							<div class="info">
								<div class="title">The perfect balance of education and entertainment</div>
								<div class="text">Your kid will learn with laughter by playing with cute characters in engaging stories.</div>
							</div>
						</div>
						<div class="item">
							<div class="image">
								<img style="top: 9px; left: 4px;" src="@/images/conclusion/items/2.svg" alt="">
							</div>
							<div class="info">
								<div class="title">Independent learning</div>
								<div class="text">Child-centered, easy-to-play games that don't need parents involvement.</div>
							</div>
						</div>
						<div class="item">
							<div class="image">
								<img style="top: -2px; left: -2px;" src="@/images/conclusion/items/3.svg" alt="">
							</div>
							<div class="info">
								<div class="title">All-round kids' development</div>
								<div class="text">All the necessary topics in one place: numbers & math, logic & problem solving, art & creativity.</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="app-links">
				<a v-if="device == 'apple'" @click="goToOneLink">
					<img src="@/images/links/app-store-logo.png" alt="">
				</a>

				<a v-if="device == 'android'" @click="goToOneLink">
					<img src="@/images/links/google-store-logo.svg" alt="">
				</a>
			</div>

			<!-- <div class="page-title subtitle">
				Other recommended apps
			</div>

			<div class="recomendation-note">
				Educational games adored by 20m parents worldwide
			</div>

			<agile :options="myOptions" class="other-recommended-apps">
				<div class="item slide">
					<div class="image">
						<img src="@/images/conclusion/other_items/1.svg" alt="">
					</div>
					<div class="info">
						<div class="title">Funny Food World</div>
						<div class="text">Full set of pre-school topics in one app. 56 learning games have been carefully designed by our early childhood educators and cover all the necessary skills for early development. </div>
					</div>
					<div class="icons-block">
						<img src="@/images/conclusion/other_items/apple.svg" alt="">
						<img src="@/images/conclusion/other_items/google-play.svg" alt="">
					</div>
				</div>
				<div class="item slide">
					<div class="image">
						<img src="@/images/conclusion/other_items/2.svg" alt="">
					</div>
					<div class="info">
						<div class="title">Bini Kids</div>
						<div class="text">Edutainment Universe for kids. Full set of educational games for preschool that covers 18 essential topics from Reading & Math to Drawing & Creativity.</div>
					</div>
					<div class="icons-block">
						<img src="@/images/conclusion/other_items/apple.svg" alt="">
						<img src="@/images/conclusion/other_items/google-play.svg" alt="">
					</div>
				</div>
				<div class="item slide">
					<div class="image">
						<img src="@/images/conclusion/other_items/3.svg" alt="">
					</div>
					<div class="info">
						<div class="title">Funny Food Academy</div>
						<div class="text">All you need to prepare for school. Funny Food Academy brings together your children’s favourite games into one educational super-project.</div>
					</div>
					<div class="icons-block">
						<img src="@/images/conclusion/other_items/apple.svg" alt="">
						<img src="@/images/conclusion/other_items/google-play.svg" alt="">
					</div>
				</div>
			</agile> -->
		</div>

		<div class="conclusion-footer">
			<div class="footer-links">
				<div class="item cert">
					<img src="@/images/footer/certificate.svg" alt="">
				</div>

				<div class="item">
					<img src="@/images/conclusion/header/add-of-day.svg" alt="">
				</div>

				<div class="item">
					<img src="@/images/conclusion/header/new-app.svg" alt="">
				</div>

				<div class="item">
					<div class="choose-icons">
						<img src="@/images/footer/choose_1.svg" alt="">
						<img src="@/images/footer/choose_2.svg" alt="">
					</div>
				</div>
			</div>
			<div class="info">
				*Additional information 
			</div>
		</div>
	</div>
</template>

<script>
	import { VueAgile } from 'vue-agile'
	import SwipeObj from '../plugins/swiper'
	import { oneLinkUrl } from '../main.js'

	export default {
		name: 'Conclusion',

		components: {
			agile: VueAgile
		},

		data () {
			return {
				oneLinkUrl: oneLinkUrl,
				myOptions: {
		            navButtons: true,
		            dots: false,
		            
		            responsive: [
		                {
		                    breakpoint: 480,
		                    settings: {
		                    	slidesToShow: 2
		                    }
		                },
		                
		                {
		                    breakpoint: 800,
		                    settings: {
		                        slidesToShow: 3
		                    }
		                }
		            ]
		        }
			}
		},
		methods: {
			goToOneLink() {
				location.href = oneLinkUrl
			}
		},
		mounted(){
			let swiperWrap = document.querySelector('.swipe-wrapper')
			var recomendedBlock = new SwipeObj();
			recomendedBlock.init(swiperWrap)
		},
		created(){
			if (navigator.userAgent.toLowerCase().match(/(ipad|iphone)/)) {
			    this.device = 'apple'
			}else{
				this.device = 'android'
			}
		}
	}
</script>

<style lang="scss">
	.agile__slides {
		.slide {
			max-width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding: 0 calc( var(--h_gap) / 2 );
		}
	}

	.agile__actions {
		--btnSize: 64px;

		position: absolute;
		top: 0;
		bottom: 0;
		left: calc( ( var(--mainPadding) + var(--h_gap) ) * -1 );
		right: calc( ( var(--mainPadding) + var(--h_gap) ) * -1 );
		display: flex;
		align-items: center;
		justify-content: space-between;
		pointer-events: none;

		@media (max-width: 1024px) {
			left: calc( var(--mainPadding) * -1 );
			right: calc( var(--mainPadding) * -1 );
		}

		.agile__nav-button {
			width: var(--btnSize);
			height: var(--btnSize);
			display: flex;
			align-items: center;
			justify-content: center;
			background: url(../images/arrow-right.svg) no-repeat 50% 50%;
			position: relative;
			font-size: 0;
			border: none;
			outline: none;
			pointer-events: auto;
			cursor: pointer;

			&--prev {
				transform: rotate(180deg);
			}
		}
	}
</style>
<style lang="scss" scoped>
	.content-container {
		--topIndent: 20px;
		--bottomIndent: 20px;

		min-height: var(--msh);
		grid-template-rows: auto 1fr auto;
		align-content: start;
		box-sizing: border-box;
		padding-top: var(--topIndent);
		padding-bottom: var(--bottomIndent);
	}

	.conclusion-header {
		height: 42px;
		display: grid;
		grid-template-columns: 100%;
		margin: 0 0 25px;

		.header-links {
			display: none;
		}

		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@media (min-width: 768px) {
			grid-template-columns: 220px 1fr 220px;
			align-items: center;
			grid-gap: 20px;

			.header-links {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 16px;
			}

			.logo {
				grid-column-start: 2;
			}
		}
	}

	.page-title {
		--fontSize: 24px;
		--bottomIndent: 20px;

		font-weight: 800;
		margin: 0 0 var(--bottomIndent);

		&:not(.subtitle) {
			text-transform: uppercase;
		}

		@media (min-width: 768px) {
			--fontSize: 36px;
			--bottomIndent: 35px;
		}
	}

	.swipe-container {
		padding: 0 var(--mainPadding);
		margin: 0 calc( var(--mainPadding) * -1 );
		overflow: hidden;

		.swipe-wrapper {
			.swipe-block {
				position: relative;
				left: var(--left);
				user-select: none;
				transition: left;
			}
		}
	}

	.recommended-apps {
		--colCount: 3;
		--h_gap: 74px;
		--itemSize: 250px;
		--columns: repeat(var(--colCount), var(--itemSize));

		width: calc( ( var(--itemSize) * var(--colCount) ) + ( var(--h_gap) * ( var(--colCount) - 1 ) ) );
		margin: 0 auto 30px;

		.item {
			width: var(--itemSize);
			position: relative;

			.image {
				width: var(--itemSize);
				height: var(--itemSize);
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				margin: 0 0 20px;

				img {
					position: relative;
					z-index: 2;
				}

				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: url(../images/conclusion/recomended_item.svg) no-repeat 50% 50%;
				}
			}

			&:not(:first-child) {
				.image {
					&:before {
						content: '';
						width: 100%;
						position: absolute;
						top: 0;
						// left: 0;
						right: calc(50% + (var(--h_gap) / 2));
						bottom: 0;
						background: url(../images/conclusion/delimiter_odd.svg) no-repeat 50% 50%;
					}
				}
			}

			&:nth-child(even) {
				.image {
					&:before {
						background-image: url(../images/conclusion/delimiter_even.svg);
					}

					&:after {
						transform: rotate(180deg);
					}
				}
			}

			.info {
				text-align: center;

				.title {
					font-weight: 800;
					font-size: 18px;
					line-height: 25px;
					margin: 0 0 5px;
				}

				.text {
					font-weight: 600;
					font-size: 15px;
					line-height: 20px;
					text-align: center;
					color: #4D4D56;
				}
			}
		}
	}

	.app-links {
		display: grid;
		grid-template-columns: 100%;
		// grid-template-columns: repeat(2, auto);
		// grid-gap: 20px;
		justify-items: center;
		margin: 0 0 35px;

		a {
			max-width: 100%;
			display: flex;

			img {
				max-width: 100%;
			}
		}
	}

	.subtitle {
		--bottomIndent: 5px;
	}

	.recomendation-note {
		font-weight: 600;
		font-size: 24px;
		line-height: 33px;
		text-align: center;
		color: #464657;
		margin: 0 0 54px;
	}

	.other-recommended-apps {
		--colCount: 3;
		--h_gap: 20px;
		--itemSize: 240px;
		--imageSize: 80px;
		--columns: repeat(var(--colCount), var(--itemSize));

		justify-content: center;
		width: calc( var(--itemSize) * var(--colCount) + ( var(--h_gap) * ( var(--colCount) ) ));
		max-width: calc( 100% - ( var(--mainPadding) * 2 ) );
		margin: 0 auto 24px;

		@media (max-width: 1024px) {
			max-width: 100%;
			box-sizing: border-box;
			padding: 0 var(--mainPadding);
		}

		.item {
			width: var(--itemSize);
			display: flex;
			flex-direction: column;
			align-items: center;

			.image {
				flex-shrink: 0;
				width: var(--imageSize);
				height: var(--imageSize);
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px solid #987126;
				box-sizing: border-box;
				border-radius: 20px;
				overflow: hidden;
				margin: 0 0 10px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.info {
				flex-grow: 1;
				text-align: center;
				margin: 0 0 10px;

				.title {
					font-weight: 800;
					font-size: 18px;
					line-height: 25px;
					margin: 0 0 5px;
				}

				.text {
					font-weight: 600;
					font-size: 15px;
					line-height: 20px;
					text-align: center;
					color: #4D4D56;
				}
			}

			.icons-block {
				--iconSize: 24px;

				flex-shrink: 0;

				.links {
					display: grid;
					grid-template-columns: repeat(2, var(--iconSize));
					grid-gap: 10px;
					justify-content: center;
				}
			}
		}
	}

	.conclusion-footer {
		display: grid;
		grid-template-columns: 100%;
		grid-gap: 10px;
		justify-content: center;
		justify-items: center;

		@media (max-width: 768px) {
			grid-gap: 16px;
		}

		.footer-links {
			display: grid;
			grid-template-columns: repeat(4, auto);
			grid-gap: 24px;
			align-items: center;

			@media (max-width: 768px) {
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 18px 26px;

				.item {
					display: flex;
					justify-content: center;
				}

				.cert {
					grid-row-start: 2;
				}
			}

			.choose-icons {
				display: grid;
				grid-template-columns: repeat(2, auto);
				grid-gap: 12px;
			}
		}

		.info {
			font-size: 12px;
			line-height: 15px;
			text-decoration: underline;
			color: var(--th_gray);
		}
	}
</style>