<template>
	<div class="wrapper">
		<current-component />
	</div>
</template>

<script>
	import CurrentComponent from "@/components/CurrentComponent";

	export default {
		components: {
			CurrentComponent
		},
		mounted(){
			document.documentElement.style.setProperty('--msh', `${window.innerHeight}px`)
			window.onresize = function(){
				document.documentElement.style.setProperty('--msh', `${window.innerHeight}px`)
			}
		}
	}
</script>

<style>
	.wrapper {
		width: 100%;
		max-width: var(--msw);
		min-height: var(--msh);
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;
		padding-left: var(--mainPadding);
		padding-right: var(--mainPadding);
	}
</style>
