export default function SwipeObj(){
    this.el = null;
    this._enabled = null;
    this._start = 0;
    this.diff = null;
    this.coordinates = 0;

    this.init = function(el, data){
        var _this = this;

        this.el = el;

        this.setDiff();
        window.onresize = function(){
            _this.setDiff();
        }

        this._move();
    }

    this.setDiff = function(){
        this.diff = this.el.querySelector('.swipe-block').offsetWidth - this.el.clientWidth;
        if(this.diff > 0)
            this._position();
    }

    this._move = function(){
        var _this = this,
            wrap = this.el,
            percentX = 0,
            coordX, coordsDiffX,
            _eDown, _eUp, _eMove;

        if('ontouchstart' in document.documentElement){
            _eDown = 'touchstart';
            _eUp = 'touchend';
            _eMove = 'touchmove';
        }else{
            _eDown = 'mousedown';
            _eUp = 'mouseup';
            _eMove = 'mousemove';
        }

        wrap.addEventListener(_eDown, _press)
        wrap.addEventListener(_eUp, _up)
        wrap.addEventListener('mouseleave', _up)

        function _press(e){
            var e = e || window.event;

            if(e.type == 'touchstart'){
                e = e.touches[0] || e.targetTouches[0].changedTouches[0];
            }else{
                if(e.which !== 1) return;
            }

            _this._start = e.pageX;

            wrap.addEventListener(_eMove, _move)
        }

        function _up(){
            wrap.removeEventListener(_eMove, _move)
        }

        function _move(ev){
            ev = ev || window.event;

            if(ev.type == 'touchmove'){
                ev = ev.touches[0] || ev.targetTouches[0].changedTouches[0];
            }

            coordX = ev.pageX;

            coordsDiffX = coordX - _this._start;

            if((_this.diff / 100) > 0){
                percentX = coordsDiffX / (_this.diff / 100);
            }

            _this.coordinates -= percentX;

            if(_this.coordinates < 0) _this.coordinates = 0;
            if(_this.coordinates > 100) _this.coordinates = 100;

            _this._start = coordX;

            _this._position();
        }
    }

    this._position = function(){
        if(!this.el || this._enabled == false) return;

        this.el.style.setProperty('--left', `${-(this.diff / 100 * this.coordinates)}px`)
    }
}